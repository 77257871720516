<template>
<Wrapper>
    <div class="serviceHall">
        <DetailBanner titleType='2' title=""></DetailBanner>
        <water-title Chinese="服务大厅"></water-title>
        <div class="content">
            <div class="nav">
                <div class="nav_item" @click="changeService(item,index)" :class="{ isActive: index === active }" v-for="(item, index) in navList" :key="index">
                    <img :src="item.imgUrl" alt="">
                    <h3>{{item.name}}</h3>
                    <span>{{item.egName}}</span>
                </div>
            </div>
            <el-menu :default-active="activeIndex" class="el-menu-demo" mode="horizontal">
                <el-menu-item index="1">{{curChecked.name}}</el-menu-item>
            </el-menu>
            <el-row :gutter="20">
                <el-col :span="6" v-for="(item,index) in (curChecked.companyList || []).slice(0, 8)" :key="index">
                    <div @click="serviceDetail(item,index)" class="imgDiv">
                        <el-image :fit="'cover'" :src="imgBaseUrl + item.logo">
                            <div slot="error" class="image-slot">
                                <i class="el-icon-picture-outline"></i>
                            </div>
                        </el-image>
                    </div>
                    <div @click="serviceDetail(item,index)" class="info">
                        <h6>{{item.name}}</h6>
                        <span>
                            <i class="el-icon-location-outline" />
                            {{item.addr}}</span>
                        <span>{{item.addDetail}}</span>
                    </div>
                </el-col>
            </el-row>
            <div v-show="curChecked.companyList && curChecked.companyList.length" class="footer" style="text-align:right">
                <el-button @click="serviceMore" type="text">查看更多>></el-button>
            </div>
        </div>
  </div>
</Wrapper>
</template>
<script>
import DetailBanner from "../sections/DetailBanner.vue";
import Wrapper from '@/components/Wrapper.vue'
import WaterTitle from "@/components/WaterTitle.vue";
import { getAllServiceList,getTopTypeList } from '@/api/modules/service.js'
import { INFO_URL } from '@/config/globalConfig'
export default {
    components: {WaterTitle, Wrapper, DetailBanner},
    data(){
        return{
            imgBaseUrl:INFO_URL,
            navList: [],
            active: 0,
            curChecked: {},
            activeIndex: '1'
        }
    },
    created(){
        const activeTab = this.$route.query.activeTab
        if(activeTab) {
            this.active = activeTab - 1
        } else {
            this.active = 0
        }
        this.scrollTop()
        this.getServiceList()
    },

    methods: {
        scrollTop() {
            const scrollTop =
                window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
                console.log(scrollTop)
            if (scrollTop === 0) {
                return false
            }
            window.scrollTo({
                top: 0,
                behavior: 'smooth'
            })
        },
        changeService(item, index){
            this.active = index
            this.curChecked = item
        },
        serviceMore(){
            localStorage.setItem('curService', JSON.stringify(this.curChecked))
            this.$router.push({
                path: '/serviceHallMoreList'
            })
        },
        serviceDetail(item, index){
          this.$router.push({
              path: `/serviceHallDetail?id=${item.id}`
          })
      },
        async getServiceList(){
            try{
                const { data } = await getTopTypeList(4)
                let map = new Map()
                map.set('花茶企业', { imgUrl: require('@/assets/imgs/tab1.png'), egName: 'SCENTED TEA' })
                map.set('金融服务', { imgUrl: require('@/assets/imgs/tab2.png'), egName: 'FINANCE' })
                map.set('传媒设计', { imgUrl: require('@/assets/imgs/tab3.png'), egName: 'MEDIA' })
                map.set('包装包材', { imgUrl: require('@/assets/imgs/tab4.png'), egName: 'PACKAGE' })
                map.set('快运物流', { imgUrl: require('@/assets/imgs/tab5.png'), egName: 'LOGISTICS' })
                map.set('机械设备', { imgUrl: require('@/assets/imgs/tab6.png'), egName: 'MACHINERY' })
                map.set('其他服务', { imgUrl: require('@/assets/imgs/tab6.png'), egName: 'OTHERS' })
                // const res = await getRecommondTop(4)
                this.navList = (data || []).map(item => {
                    let curService = map.get(item.name)
                    item.imgUrl = curService ? curService.imgUrl : require('@/assets/imgs/tab6.png')
                    item.egName = curService ? curService.egName : 'OTHERS'
                    return item
                })
                this.curChecked = this.navList[0]
                // this.curChecked.companyList =
                console.log("getServiceList:", this.curChecked);
            }finally{

            }
        }
    }
}
</script>
<style lang="scss" scoped>
.serviceHall{
    margin-top: 50px;
    // margin-bottom: 50px;
}
.content{
    min-height: 500px;
    width: 1200px;
    margin: auto;
    margin-top: 50px;
    margin-bottom: 50px;
    .nav{
        display: flex;
        justify-content: space-between;
        .nav_item{
            padding: 30px 0;
            flex: 0 0 14%;
            box-shadow: 6px 0px 15px 0px #F3F5F8;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            border-bottom: 2px solid #F3F5F8;
            img{
                margin-bottom: 30px;
            }
            h3{
                font-size: 24px;
                font-family: PingFang-SC-Bold, PingFang-SC;
                font-weight: bold;
                margin-bottom: 15px;
                color: #181F2D;
            }
            span{
                color: #999999;
                font-size: 20px;
            }
        }
        .isActive{
            h3{
                color: #12378D;
            }
            border-bottom-color:#12378D;
        }
    }
    .el-menu{
        margin-top: 50px;
        .el-menu-item{
            font-size: 22px;
            font-family: PingFang-SC-Bold, PingFang-SC;
            font-weight: bold;
            color: #181F2D;
        }
    }
    .el-row{
        margin-top: 30px;
        .el-col{
            cursor: pointer;
            margin-bottom: 30px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            .imgDiv{
                text-align: center;
                background-color: #F3F5F8;
                border-radius: 20px 20px 0 0;
                padding: 15px 0;
                width: 100%;
                .el-image{
                    border-radius: 50%;
                    width: 152px;
                    height: 152px;
                }
            }
            .info{
                width: 100%;
                border-radius: 0 0 20px 20px;
                background-color: #315CCF;
                display: flex;
                flex-direction: column;
                color: #fff;
                padding: 30px;
                line-height: 30px;
                text-align: center;
                h6{
                    font-weight: bold;
                }
            }
        }
    }
    .footer{
        .el-button{
            font-size: 18px;
            font-family: SourceHanSansCN-Regular, SourceHanSansCN;
        }
    }
}
</style>

